import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Cyclic Rotation – Coditility",
  "author": "tzookb",
  "type": "post",
  "date": "2019-06-08T05:51:23.000Z",
  "url": "/2019/06/cyclic-rotation-coditility/",
  "medium_post": ["O:11:\"Medium_Post\":11:{s:16:\"author_image_url\";N;s:10:\"author_url\";N;s:11:\"byline_name\";N;s:12:\"byline_email\";N;s:10:\"cross_link\";N;s:2:\"id\";N;s:21:\"follower_notification\";N;s:7:\"license\";N;s:14:\"publication_id\";N;s:6:\"status\";N;s:3:\"url\";N;}"],
  "categories": ["Exercises"],
  "tags": ["code kata", "coditility", "exercise"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{` Let`}{`’`}{`s share three different ways to solve this problem and each solution will improve the previous one. The problem:`}<br parentName="p"></br>{`
`}{`you get a big array of integers `}<inlineCode parentName="p">{`A`}</inlineCode>{` and a number `}<inlineCode parentName="p">{`K`}</inlineCode>{` as the amount of shifts to the right. Example: `}</p>
    <pre><code parentName="pre" {...{}}>{`arr = [1,2,3,4,5]
k = 2
//solution
[4,5,1,2,3]
`}</code></pre>
    <a rel="noreferrer noopener" aria-label="like to the exercise (opens in a new tab)" href="https://app.codility.com/programmers/lessons/2-arrays/cyclic_rotation/" target="_blank">like to the exercise</a>  
    <a rel="noreferrer noopener" aria-label="link to github code (opens in a new tab)" href="https://github.com/tzookb/programming-challenges/tree/master/codility/CyclicRotation" target="_blank">link to github code</a> 
    <h2>{`First Solution This version is just looping`}</h2>
    <p><inlineCode parentName="p">{`K`}</inlineCode>{` times and `}<inlineCode parentName="p">{`pop`}</inlineCode>{` and `}<inlineCode parentName="p">{`push`}</inlineCode>{` to the start: `}</p>
    <pre><code parentName="pre" {...{}}>{`i = 0
while i &lt; K:
  lastItem = arr.pop()
  arr.prepend(lastItem) # push to the start of the array
return arr
`}</code></pre>
    <h2>{`Second Solution Quite similar to the previous one, but this time we calculate`}</h2>
    <p><inlineCode parentName="p">{`K`}</inlineCode>{` to the min rotations needed. Lets say we have an array of `}{`[1,2,3]`}{`, now if will have 3,6,9,12,`}{`…`}{`.. rotations the array will stay in the exact order. That means we can cut all of those useless rotations with: `}</p>
    <pre><code parentName="pre" {...{}}>{`arrLength = len(arr)
newK = arrLength % K
# newK is what we loop over now
# so we may have saved a lot of useless rotations
i = 0
while i &lt; newK:
  lastItem = arr.pop()
  arr.prepend(lastItem) # push to the start of the array
return arr
`}</code></pre>
    <h2>{`My Final Solution: Instead of looping over the array and popping and pushing I can just split the array where I need and reconnect it. so we have this array:`}</h2>
    <p><inlineCode parentName="p">{`[1,2,3,4,5,6,7,8]`}</inlineCode>{` and `}<inlineCode parentName="p">{`K=3`}</inlineCode>{` So the result should be `}<inlineCode parentName="p">{`[6,7,8,1,2,3,4,5]`}</inlineCode>{` as you can see this is like joining these two arrays `}<inlineCode parentName="p">{`[6,7,8]`}</inlineCode>{` `}<inlineCode parentName="p">{`[1,2,3,4,5]`}</inlineCode>{` so the first array would be:`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`[arrLen - newK ...... arrLen]`}</inlineCode><br parentName="p"></br>{`
`}{`and the second`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`[0 ...... arrLen-1]`}</inlineCode>{` This is my solution (python) `}</p>
    <pre><code parentName="pre" {...{}}>{`def solution(arr , k):
    arrLen = len(arr)
    if arrLen == 0:
        return arr
    moves = k % arrLen
    return arr[arrLen-moves:arrLen] + arr[0:arrLen-1]
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      